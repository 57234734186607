import './App.css';

function App() {
  return (
    <div className="App">

<center>
	 <img src="https://i.imgur.com/rxr8Vmx.jpg" alt="Logo Empresa" height="50%" width="50%"></img>
</center>
<audio   id="jp_audio_0" controls src="https://us2freenew.listen2myradio.com/live.mp3?typeportmount=s1_13836_stream_958523833"> </audio>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br><br></br>
<br></br>
<br></br><br></br>
<br></br>
<br></br>
<br></br>
    <footer>
    <p>  Copyright © 2023 Mercado de importaciones | Departamento de mejora continua </p>
    </footer>
    </div>
  );
}

export default App;
